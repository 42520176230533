import * as React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import { respondTo } from "../../_respondTo";

import { StaticImage } from "gatsby-plugin-image";
import logo from "../../images/twm-logo-white.svg";

import { ModalWrapper } from "../../components/layout";

/*
const GlobalStyle = createGlobalStyle`
  body{
    margin:0;
  }
`;
*/
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(2, 15, 115, 0.7);
`;

const LogoWrapper = styled.div`
  margin: auto;
  width: 50%;
  max-width: 250px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  color: white;
  text-align: center;
  padding: 20px;
  font-family: sans-serif;
  line-height: 1.6;

  a {
    color: white;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;

  a {
    color: white;
    text-decoration: none;
    font-size: 150%;
    margin: 1em;
  }
`;

const LsnifaPage = ({ location }) => {
  const closeUrl = !location.state ? null : location.state.closeToUrl;
  return (
    <ModalWrapper>
      <Helmet>
      <script defer src="https://unpkg.com/@tinybirdco/flock.js" data-host="https://api.tinybird.co" data-token="p.eyJ1IjogImI3YzA5NWQxLThiMTctNGQ5Ni04ODUwLWUxNjM0YmU5NzgyOCIsICJpZCI6ICI4Njg5NGY0MC00ZjBlLTRiMGMtYTI2YS0xMmNkZTBlYjEyOWMifQ.cXvEL-7r0R4anvPPduWPoDsUKPPZeyI54Z2BuA_L5E8"></script>
      </Helmet>
      <StaticImage
        alt="Mountain range"
        src={"../../images/bg-firm.jpg"}
        formats={["auto", "webp", "avif"]}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      />
      <Overlay>
        <NavWrapper>
          <Link
            to="/firms/navigator-financial-planning/"
            state={{
              modal: true,
              closeToUrl: closeUrl,
            }}
          >
            &lt;
          </Link>
          <Link to={closeUrl}>[x]</Link>
          <Link
            to="/firms/watson-laird/"
            state={{
              modal: true,
              closeToUrl: closeUrl,
            }}
          >
            &gt;
          </Link>
        </NavWrapper>
        <LogoWrapper>
          <img
            src={logo}
            style={{ width: "100%", paddingTop: "25%" }}
            alt="True Wealth Management logo"
          />
        </LogoWrapper>{" "}
        <ContentWrapper>
          <p>
            Established by the founders of the True Wealth Group over a decade
            ago, True Wealth Management is the Group's 'own branded' financial
            planning wealth management firm. The Group's management team has
            been using the word 'True' in its branding since 2003 (originally
            'True Financial Planning') to capture the nature and integrity of
            its propositions.
          </p>
          <a
            href="https://truewm.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit website
          </a>{" "}
        </ContentWrapper>
      </Overlay>
    </ModalWrapper>
  );
};

export default LsnifaPage;

export function Head() {
  return (
    <>
      <title>True Wealth Group</title>
      <meta name="description" content="Website of True Wealth Group" />
      <meta name="keywords" content="True Wealth Group" />
    </>
  );
}
